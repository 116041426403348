/* eslint-disable import/prefer-default-export */

import dayjs from '../lib/dayjs';

export const formatDateMMMMDoYYYY = (
  dateTimeStamp: string,
  timezone: string,
) => {
  return dayjs.utc(dateTimeStamp).tz(timezone).format('MMMM Do, YYYY');
};

export const formatTimehhmmA = (dateTimeStamp: string, timezone: string) => {
  return dayjs.utc(dateTimeStamp).tz(timezone).format('hh:mm A');
};

export const formatRideTime = (dateField: Date, timeField: Date) => {
  const day = new Date(dateField);
  const time = new Date(timeField);

  day.setHours(time.getHours());
  day.setMinutes(time.getMinutes());
  day.setSeconds(time.getSeconds());
  day.setMilliseconds(time.getMilliseconds());

  return day;
};

export const weekdays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
export const shortWeekdays = [
  'Sun',
  'Mon',
  'Tues',
  'Wed',
  'Thurs',
  'Fri',
  'Sat',
];

export const getDate = (date: string | Date = new Date()) =>
  new Date(date).getDate();
export const getDay = (date: string | Date = new Date()) =>
  new Date(date).getDay();
export const getDayNameByNumber = (day: number) => weekdays[day];
export const getDayNameByDate = (date: string | Date = new Date()) =>
  getDayNameByNumber(new Date(date).getDay());
export const getShortDayNameByNumber = (day: number) => shortWeekdays[day];
export const getShortDayNameByDate = (date: string | Date = new Date()) =>
  getShortDayNameByNumber(new Date(date).getDay());

export const getShortMonth = (date: string | Date = new Date()) =>
  new Date(date).toLocaleDateString('en', { month: 'short' });

export const getTimeHHMM = (date: string | Date = new Date()) =>
  new Date(date).toLocaleString('en-US', {
    hour: 'numeric',
    hour12: true,
    minute: 'numeric',
  });

export const getShortTz = (date: string | Date = new Date()) =>
  new Date(date)
    .toLocaleString('en', { timeZoneName: 'short' })
    .split(' ')
    .pop() as string;
