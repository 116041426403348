import React, { useState } from 'react';

import type { Ride } from '@/types';

import { useGetRideEtaResponsesQuery } from '@/api/rides/getRideEtaResponses';
import Loader from '@/components/Spinner/Spinner';
import RideStatusBadge from '@/features/RideStatusBadge';

const singular = ' response received';
const plural = ' responses received';

const ResponsesReceived: React.FC<{ ride: Ride }> = ({ ride }) => {
  const [open, setOpen] = useState(false);
  const onOpenChange = (o: boolean) => setOpen(o);

  const {
    isLoading,
    isError,
    data: etaResponses = [],
  } = useGetRideEtaResponsesQuery(ride.id, {
    skip: !open,
  });

  const text = etaResponses.length === 1 ? singular : plural;

  return (
    <RideStatusBadge.Tooltip onOpenChange={onOpenChange}>
      {isLoading && <Loader className="inline" size="small" />}
      {isError && <p>Some error occurred.</p>}

      {!isError && !isLoading && (
        <p className="tooltip-text">
          {etaResponses?.length}
          {text}
        </p>
      )}
    </RideStatusBadge.Tooltip>
  );
};

export default ResponsesReceived;
