import { createBrowserRouter, Outlet } from 'react-router-dom';

import Container from '@/components/dispatcher/Container';
import ComponentWrapper from '@/config/ComponentWrapper';
import { routes } from '@/path_defs';

import AdminScheduled from './pages/admin/Scheduled';
import CcScheduled from './pages/careCoordinator/Scheduled';
import Community from './pages/dispatcher/Community';
import DispatcherScheduled from './pages/dispatcher/Scheduled';

const router = createBrowserRouter([
  {
    path: '/',
    Component: () => ComponentWrapper(Outlet),
    children: [
      {
        path: 'dispatcher',
        Component: Container,
        children: [
          {
            path: 'community',
            Component: Community,
          },
          {
            path: 'scheduled',
            Component: DispatcherScheduled,
          },
        ],
      },
      {
        path: 'trips',
        Component: Container,
        children: [
          {
            path: 'scheduled',
            Component: CcScheduled,
          },
        ],
      },
      {
        path: 'admin',
        Component: Container,
        children: [
          {
            path: 'trips',
            children: [
              {
                path: 'scheduled',
                Component: AdminScheduled,
              },
            ],
          },
        ],
      },
    ],
  },
]);

// eslint-disable-next-line @typescript-eslint/no-misused-promises
document.addEventListener('turbolinks:load', () => {
  const curr = window.location.pathname;

  if (routes.includes(curr)) {
    return router.navigate(window.location.pathname);
  }

  return undefined;
});

export type Router = typeof router;

export default router;
