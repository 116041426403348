import * as React from 'react';
import dayjs from '@/lib/dayjs';

import Cell from '@/features/RidesTable/components/Table/Cell';

type TimeCellProps = {
  href: string;
  label?: string;
  time: string;
  timeZone?: string;
  willCall?: boolean;
};

const TimeCell: React.FC<TimeCellProps> = ({
  time,
  href,
  willCall = false,
  label = 'Book time',
  timeZone = 'America/New_York',
}) => {
  const rideDayjs = dayjs.utc(time).tz(timeZone);

  const shortDayName = rideDayjs.format('ddd');     // e.g. "Mon"
  const shortMonth = rideDayjs.format('MMM');     // e.g. "Jan"
  const dayOfMonth = rideDayjs.format('D');       // e.g. "7"
  const hhmm = rideDayjs.format('h:mm A');  // e.g. "9:05 AM"
  const shortTz = rideDayjs.format('z');       // e.g. "EST"

  return (
    <Cell label={label} tdClass="book-location" href={href}>
      <span className="no-wrap">
        {`${shortDayName}, ${shortMonth} ${dayOfMonth}`}
        <br />
        {willCall ? 'Will Call' : `${hhmm} ${shortTz}`}
      </span>
    </Cell>
  );
};

export default TimeCell;