/* eslint-disable max-len */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable global-require */
import isEmpty from '@/utils/isEmpty';
import ComponentWrapper from './ComponentWrapper';
import App from '@/App';

/**
 * The API server currently does not expose all necessary functions to load the application.
 * For example, we have no way of fetching the current user. In the interim,
 * we have to pass in the data server-side and access it client-side.
 *
 * This object gives components a way to access SSR data but also provides us with a searchable list of components
 * that are coupled to the backend server.
 */
window._unsafeSSRData = {};

let initialized = false;

const setupReactRails = () => {
  if (initialized) {
    if (process.env.NODE_ENV === 'development') {
      console.warn('React Rails has already been initialized. Attempted to initialize again');
    }

    return;
  }

  const componentRequireContext = require.context('../components', true);
  const ReactRailsUJS = require('react_ujs');
  ReactRailsUJS.useContext(componentRequireContext);

  /**
   * `ReactRailsUJS.mountComponents` is run on browser load events & turbolinks:load events.
   * `ReactRailsUJS.mountComponents` invokes `getConstructor` passing in the string value
   * provided to `react_component` methods calls in HAML and returning the React Component class function.
   *
   * This bit of code is a monkey patch to abstract the need to wrap every individual
   * React component with any/all Providers & Contexts.
   */
  const constructor = ReactRailsUJS.getConstructor;
  ReactRailsUJS.getConstructor = (requireContext) => ({
    currentUser,
    environment,
    flash,
    token,
    isDriver,
    transportationCompanyId,
    userMetadata,
    ...props
  }) => {
    if (typeof window._unsafeSSRData === "undefined") {
      console.log('window._unsafeSSRData was undefined at component render')
      window._unsafeSSRData = {}
    }

    window._unsafeSSRData.global = {
      currentUser,
      environment,
      flash,
      token,
      isDriver,
      transportationCompanyId,
      userMetadata,
    }

    if (!isEmpty(props)) {
      /**
       * Prevent a component from overwriting another data by storing its props under
       * a key named after itself.
       *
       * Ex: `RidesModal` can access SSR data via `window._unsafeSSRData.RidesModal`.
       */

      window._unsafeSSRData[requireContext] = props;
    }

    if (requireContext === 'App') {
      return App()
    }

    return ComponentWrapper(constructor(requireContext));
  }

  initialized = true;
};

export default setupReactRails;
