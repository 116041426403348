// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-text {
  color: var(--purple-600);
  font-weight: bold;
}

button.toggle-option-proxy {
  height: 38.099px;
  width: 175.208px;
}

button.quick-action-option {
  display: flex;
  justify-content: flex-start;
}`, "",{"version":3,"sources":["webpack://./app/javascript/components/RidesTable/QuickActionCell/careCoordinator/main.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,2BAA2B;AAC7B","sourcesContent":[".toggle-text {\n  color: var(--purple-600);\n  font-weight: bold;\n}\n\nbutton.toggle-option-proxy {\n  height: 38.099px;\n  width: 175.208px;\n}\n\nbutton.quick-action-option {\n  display: flex;\n  justify-content: flex-start;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
