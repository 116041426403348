import React, { useState } from 'react';

import ChevronDownIcon from '@images/chevron-down.svg';
import DateField from '@root/components/DateField';
import TimeField from '@root/components/TimeField';
import { useDispatch } from 'react-redux';

import { useUpdateRideMutation } from '@/api';
import { useTableContext } from '@/features/RidesTable/providers/TableProvider';
import { pushFlashMessage } from '@/store/flashMessagesSlice';
import { pushToastNotification } from '@/store/toastNotificationsSlice';
import { formatRideTime } from '@/utils/dateTime';

import Modal from '../Modal';

const ChangeRideTimeModal: React.FC<{
  direction: string;
  dropoffStreetAddress: string;
  hide: () => void;
  pickupStreetAddress: string;
  rideId: number;
  rideStartTime: Date;
  show: boolean;
  timezone: string;

  willCall: boolean;
}> = ({
  rideId,
  rideStartTime,
  hide,
  show,
  pickupStreetAddress,
  dropoffStreetAddress,
  timezone,
  direction,
  willCall,
}) => {
  const dispatch = useDispatch();
  const [dateField, setDateField] = useState(rideStartTime);
  const [timeField, setTimeField] = useState(rideStartTime);
  const [isPickup, setIsPickup] = useState(true);
  const [isWillCall, setIsWillCall] = useState(false);

  const { updateRideById } = useTableContext();

  const [updateRide] = useUpdateRideMutation();

  const timeTypeKey = `${direction}TimeType`;
  const datetimeKey = `${direction}Datetime`;

  const minTime = new Date();
  minTime.setHours(0, 0, 0, 0);

  const title = willCall ? 'Set ride time' : 'Change ride time';
  const { label, preposition, address } = isPickup
    ? { label: 'Pickup', preposition: 'at', address: pickupStreetAddress }
    : { label: 'Dropoff', preposition: 'to', address: dropoffStreetAddress };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const combinedDate = formatRideTime(dateField, timeField);

    const body = {
      id: rideId,
      willCall: isWillCall ? 'will_call' : null,
      [timeTypeKey]: isPickup ? 'pickup' : 'dropoff',
      [datetimeKey]: combinedDate,
    };

    updateRide(body)
      .unwrap()
      .then(() => {
        hide();
        dispatch(
          pushToastNotification({
            text: 'Trip has been successfully updated.',
            rideId,
            action: 'view ride',
          }),
        );
        updateRideById(rideId, { hasBid: true });
      })
      .catch(() => {
        dispatch(
          pushFlashMessage({
            text: 'Unable to update trip at the moment. Please try again in a few minutes.',
            type: 'error',
          }),
        );
      });
  };

  const toggleText = () => {
    setIsPickup((prevState) => !prevState);
  };

  const toggleWillCall = () => {
    setIsWillCall((prevState) => !prevState);
  };

  return (
    <Modal open={show} hide={hide} title={title} subtitle="">
      <div style={{ cursor: 'pointer' }} className="pb-2">
        <button type="button" onClick={toggleText} className="toggle-text">
          {label} time
          <ChevronDownIcon style={{ width: '12px', height: '12px' }} />
        </button>{' '}
        {preposition} {address}
      </div>
      <form
        className="form p-0"
        id="cancel-ride"
        acceptCharset="UTF-8"
        method="delete"
        onSubmit={handleSubmit}
      >
        <div className="d-flex flex-column">
          <div className="d-flex">
            <DateField
              initialDate={rideStartTime}
              minDate={rideStartTime}
              onChange={setDateField}
              className="-medium"
            />
            {!isWillCall && (
              <TimeField
                timeInterval="5"
                initialTime={rideStartTime}
                minTime={minTime}
                onChange={setTimeField}
                position="top-end"
                tripTimezone={timezone}
                className="-medium"
              />
            )}
            <button
              type="button"
              style={{ marginLeft: '0.5rem' }}
              className="toggle-option-proxy -medium-font-size mt-sm-0 mt-1"
              onClick={toggleWillCall}
            >
              Will Call
            </button>
          </div>
          <div className="alert-dialog-actions mt-6">
            <button
              type="button"
              className="button -medium -inverted-blue"
              onClick={hide}
            >
              Nevermind
            </button>

            <button
              data-test="cancel-ride-submit-btn"
              type="submit"
              className="button -medium"
            >
              Set time
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ChangeRideTimeModal;
