import React, { useMemo, useState } from 'react';

import type { DeleteRide, Ride } from '@/types';

import { QueryStatus } from '@reduxjs/toolkit/query';
import { toSnake } from '@root/util/CaseConvert';
import { useDispatch } from 'react-redux';

import {
  useDeleteRideMutation,
  useGetRoundtripDetailsQuery,
  useGetScheduledRidesQuery,
  useGetTripQuery,
} from '@/api';
import Spinner from '@/components/Spinner/Spinner';
import { useTableContext } from '@/features/RidesTable/providers/TableProvider';
import * as AlertDialog from '@/lib/@radix-ui/react-alert-dialog';
import * as DropdownMenu from '@/lib/@radix-ui/react-dropdown-menu';
import * as Tabs from '@/lib/@radix-ui/react-tabs';
import { pushFlashMessage } from '@/store/flashMessagesSlice';
import { pushToastNotification } from '@/store/toastNotificationsSlice';
import {
  CANCEL_RIDE_OPTIONS,
  CANCEL_RIDE_TEXT,
  CANCELLATION_REASONS_TEXT,
} from '@/types';
import { formatDateMMMMDoYYYY, formatTimehhmmA } from '@/utils/dateTime';

import Modal from '../../Modal';
import CancelSeries from './CancelSeries';
import CancelSingleRide from './CancelSingleRide';
import CancelTrip from './CancelTrip';

const { cancelRide, cancelTrip, cancelSeries } = CANCEL_RIDE_TEXT;
const { singleRide, entireTrip, entireSeries } = CANCEL_RIDE_OPTIONS;

/* eslint-disable camelcase */
const OPTIONS = {
  single_ride: cancelRide,
  entire_trip: cancelTrip,
  entire_series: cancelSeries,
};
/* eslint-enable camelcase */

const populateRoundtripInfo = ([a, b]: Ride[], timeZone: string) => {
  const outboundRide = a.direction === 'outbound' ? a : b;
  const returnRide = a.direction === 'return' ? a : b;

  return {
    outboundDate: formatDateMMMMDoYYYY(outboundRide.startTime, timeZone),
    outboundTime: formatTimehhmmA(outboundRide.startTime, timeZone),
    returnDate: formatDateMMMMDoYYYY(returnRide.startTime, timeZone),
    returnTime: formatTimehhmmA(returnRide.startTime, timeZone),
  };
};

const CancelTripModal: React.FC<{
  hide: () => void;
  navCCPresent: boolean;
  otherRideId?: number;
  rideId: number;
  rideTime: string;
  rideTimezone: string;
  roundtrip: boolean;
  show: boolean;
  tripId: number;
}> = ({
  hide,
  rideId,
  tripId,
  roundtrip,
  navCCPresent = false,
  show,
  otherRideId,
  rideTime,
  rideTimezone,
}) => {
  const dispatch = useDispatch();
  const [deleteRide] = useDeleteRideMutation();
  const { removeRideById } = useTableContext();

  const [additionalInfo, setAdditionalInfo] = useState('');
  const [cancellationReasonOn, setCancellationReasonOn] = useState(true);
  const [scheduleId, setScheduleId] = useState<number | null>(null);
  const [selectedTab, setselectedTab] =
    useState<keyof typeof OPTIONS>('single_ride');
  const [selectedReason, setSelectedReason] = useState<
    keyof typeof CANCELLATION_REASONS_TEXT | ''
  >('');

  const tripQueryRes = useGetTripQuery(tripId, { skip: !show });
  const roundtripDetailsRes = useGetRoundtripDetailsQuery(tripId, {
    skip: selectedTab !== entireTrip,
  });

  const { data: scheduled = { rides: [] }, ...scheduledRidesQueryRes } =
    useGetScheduledRidesQuery(
      {
        scheduleId: scheduleId!,
        currentStatus: [
          'incomplete',
          'queued',
          'claimed',
          'to_pickup',
          'at_pickup',
          'to_dropoff',
          'at_dropoff',
        ],
      },
      { skip: selectedTab !== entireSeries || scheduleId === null },
    );

  if (
    tripQueryRes.data?.scheduleId &&
    scheduleId !== tripQueryRes.data.scheduleId
  ) {
    setScheduleId(tripQueryRes.data.scheduleId);
  }

  const { outboundDate, outboundTime, returnDate, returnTime } = useMemo(() => {
    if (
      roundtripDetailsRes.status !== QueryStatus.fulfilled ||
      !roundtripDetailsRes?.data?.rides
    ) {
      return {
        outboundDate: null,
        outboundTime: null,
        returnDate: null,
        returnTime: null,
      };
    }

    return populateRoundtripInfo(roundtripDetailsRes.data.rides, rideTimezone);
  }, [
    rideTimezone,
    roundtripDetailsRes.data?.rides,
    roundtripDetailsRes.status,
  ]);

  const handleReasonChange = (value: string) =>
    setSelectedReason(value as keyof typeof CANCELLATION_REASONS_TEXT);
  const handleAdditionalInfoChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => setAdditionalInfo(event.target.value);
  const toggleSwitch = () =>
    setCancellationReasonOn((prevSwitch) => !prevSwitch);

  const notify = () => {
    dispatch(
      pushToastNotification({
        text: 'Rides successfully canceled.',
        rideId,
        action: 'view ride',
      }),
    );
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const params: DeleteRide = {
      body: {
        additionalCancelationInfo: additionalInfo,
        cancelationReason: toSnake(selectedReason) as string,
        sendNotifications: cancellationReasonOn,
        id: rideId,
      },
      rideId,
    };

    if (OPTIONS[selectedTab] === cancelTrip) {
      params.body.tripId = tripId;
    } else if (OPTIONS[selectedTab] === cancelSeries && scheduleId !== null) {
      params.body.scheduleId = scheduleId;
    }

    deleteRide(params)
      .unwrap()
      .then(() => {
        if (OPTIONS[selectedTab] === cancelTrip) {
          removeRideById(rideId);

          if (otherRideId) {
            removeRideById(otherRideId);
          }
        } else if (OPTIONS[selectedTab] === cancelSeries) {
          window.location.reload();
        } else {
          removeRideById(rideId);
          hide();
        }

        notify();
      })
      .catch(() => {
        dispatch(
          pushFlashMessage({
            text: 'Sorry, this ride cannot be canceled at the moment. Please try again in a few minutes.',
            type: 'error',
          }),
        );
      });
  };

  const lastRideDate = scheduled.rides.at(-1)?.startTime;

  return (
    <Modal open={show} hide={hide} title="Cancel this ride?" subtitle="">
      <div className="d-inline">
        <form
          className="form p-0"
          id="cancel-ride"
          acceptCharset="UTF-8"
          method="delete"
          onSubmit={handleSubmit}
        >
          {(roundtrip || scheduleId) && (
            <Tabs.Root
              defaultValue={singleRide}
              onValueChange={(value) =>
                setselectedTab(value as keyof typeof OPTIONS)
              }
              value={selectedTab}
            >
              <Tabs.List
                aria-label="tabs example"
                className="d-flex tab-labels"
              >
                <Tabs.Trigger className="tab-label" value={singleRide}>
                  Just this ride
                </Tabs.Trigger>
                {roundtrip && (
                  <Tabs.Trigger className="tab-label" value={entireTrip}>
                    Entire trip
                  </Tabs.Trigger>
                )}
                {scheduleId && (
                  <Tabs.Trigger className="tab-label" value={entireSeries}>
                    Entire series
                  </Tabs.Trigger>
                )}
              </Tabs.List>

              <div className="box p-4 mt-3">
                <Tabs.Content value={singleRide}>
                  <CancelSingleRide
                    rideTime={rideTime}
                    rideTimezone={rideTimezone}
                  />
                </Tabs.Content>
                <Tabs.Content value={entireTrip}>
                  {!roundtripDetailsRes.isFetching &&
                  !roundtripDetailsRes.isError ? (
                    <CancelTrip
                      outboundDate={outboundDate || ''}
                      outboundTime={outboundTime || ''}
                      returnDate={returnDate || ''}
                      returnTime={returnTime || ''}
                    />
                  ) : (
                    <Spinner size="large" />
                  )}
                </Tabs.Content>
                <Tabs.Content value={entireSeries}>
                  {!scheduledRidesQueryRes.isFetching &&
                  !scheduledRidesQueryRes.isError &&
                  lastRideDate ? (
                    <CancelSeries
                      lastRideDate={lastRideDate}
                      ridesCount={scheduled.rides.length}
                      timeZone={rideTimezone}
                    />
                  ) : (
                    <Spinner size="large" />
                  )}
                </Tabs.Content>
              </div>
            </Tabs.Root>
          )}

          <div className="field">
            <label htmlFor="cancelation_reason" className="label">
              Reason <span className="required">*</span>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                  <DropdownMenu.Label className="DropdownMenuLabel">
                    {(selectedReason &&
                      CANCELLATION_REASONS_TEXT[selectedReason]) ||
                      'Select a reason'}
                  </DropdownMenu.Label>
                </DropdownMenu.Trigger>
                <DropdownMenu.Portal>
                  <DropdownMenu.Content
                    className="DropdownMenuContent"
                    sideOffset={5}
                  >
                    <DropdownMenu.RadioGroup
                      value={selectedReason}
                      onValueChange={handleReasonChange}
                      id="cancelation_reason"
                    >
                      {Object.entries(CANCELLATION_REASONS_TEXT).map(
                        ([key, text]) => (
                          <DropdownMenu.RadioItem
                            className="DropdownMenuRadioItem"
                            value={key}
                          >
                            {text}
                          </DropdownMenu.RadioItem>
                        ),
                      )}
                    </DropdownMenu.RadioGroup>
                  </DropdownMenu.Content>
                </DropdownMenu.Portal>
              </DropdownMenu.Root>
            </label>
          </div>

          <div className="field mt-1" role="button" tabIndex={0}>
            <label htmlFor="additional_cancelation_info" className="label">
              Additional information
              <textarea
                id="additional_cancelation_info"
                name="additional_cancelation_info"
                value={additionalInfo}
                onChange={handleAdditionalInfoChange}
                className="input -tall"
              />
            </label>
          </div>

          {navCCPresent && (
            <div className="switch-container d-flex flex-justify-between mb-6">
              <p>Send cancellation notifications</p>

              <label className="switch" htmlFor="cancellation_notifications">
                <span className="sr-only">
                  Toggle cancellation notifications
                </span>
                <input
                  id="cancellation_notifications"
                  type="checkbox"
                  checked={cancellationReasonOn}
                  onChange={toggleSwitch}
                />
                <span className="slider" />
              </label>
            </div>
          )}

          <div
            style={{
              display: 'flex',
              gap: 8,
              justifyContent: 'flex-end',
            }}
          >
            <AlertDialog.Cancel asChild>
              <button
                type="button"
                className="button py-2 -inverted-blue"
                onClick={hide}
              >
                Nevermind
              </button>
            </AlertDialog.Cancel>

            <button
              data-test="cancel-ride-submit-btn"
              type="submit"
              className="button py-2 -cancel"
            >
              {OPTIONS[selectedTab]}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CancelTripModal;
